<template>
  <v-app-bar app color="secondary" clipped-right clipped-left>
    <v-img @click="$router.push('/login')" style="cursor: pointer" max-width="50" :src="`https://app.fatduckgaming.com/assets/jobs/${this.$store.state.useJob}.png`"></v-img>
    <v-divider class="pl-4" vertical></v-divider>

    <v-row v-if="!$vuetify.breakpoint.mobile" class="pl-4 d-flex align-center">
      <v-menu offset-y v-for="link in navBarLinks.left" :key="link.label" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" text v-bind="attrs" v-on="on" @click="showMenu = null, showMenu = link">
            <v-icon >{{link.icon}}</v-icon>
          </v-btn>
        </template>
        <v-list nav v-if="link && link.menus" hover class="pa-5">
          <h3>{{link.header}}</h3>
          <v-list-item :disabled="!($store.state.permissions[sublink.permission])" :to="`${sublink.link}`" v-for="sublink in link.menus" :key="sublink.label" link color="primary" class="pa-0 ma-0">
            <v-list-item-icon>
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ sublink.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <!-- <v-text-field outlined dense prepend-inner-icon="mdi-magnify" class="d-flex align-center pt-6 pl-8 pr-8"></v-text-field> -->

      <v-menu offset-y v-for="link in navBarLinks.right" :key="link.label" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" text v-bind="attrs" v-on="on" @click="showMenu = null, showMenu = link">
            <v-icon >{{link.icon}}</v-icon>
          </v-btn>
        </template>
        <v-list nav v-if="link && link.menus" class="pa-5">
          <h3>{{link.header}}</h3>
          <v-list-item :disabled="!($store.state.permissions[sublink.permission])" :to="`${sublink.link}`" v-for="sublink in link.menus" :key="sublink.label" link color="primary" class="pa-0 ma-0">
            <v-list-item-icon>
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ sublink.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-btn class="ml-5" icon @click="$router.push('/home')">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-divider vertical class="pl-4"></v-divider>
    <header-account darkModeToggle class="ml-4" />
  </v-app-bar>
</template>

<script>
import HeaderAccount from '@common/components/auth/HeaderAccount.vue';
export default {
  components: { HeaderAccount },
  name: 'businesses-header',
  data() {
    return {
      showMenu: null,
      navBarLinks: {
        left: [
          // {icon: 'mdi-currency-usd', link: '/finance/summary', header: "Business Finance", 
          //   menus: [
          //     {icon: 'mdi-currency-usd', link: '/finance/summary', label: "Summary", permission: "FI_SummaryView"},
          //     {icon: 'mdi-currency-usd', link: '/finance/expenses', label: "Expenses", permission: "FI_ExpenseView"},
          //     {icon: 'mdi-currency-usd', link: '/finance/income', label: "Income", permission: "FI_IncomeView"},
          //   ]
          // },
          {icon: 'mdi-account-group', link: '/hr/home', header: "Human Resources", 
            menus: [
              {icon: 'mdi-account', link: '/hr/administration', label: "Employees", permission: "HR_AdministrationView"},
              {icon: 'mdi-form-select', link: '/hr/applications', label: "Applications", permission: "HR_ApplicationView"},
              {icon: 'mdi-table-account', link: '/hr/directory', label: "Directory", permission: "HR_AdministrationView"},
            ]
          },
          {icon: 'mdi-pencil-outline', link: '/job/ranks', header: "Job Structure", 
            menus: [
              {icon: 'mdi-list-box-outline', link: '/job/ranks', label: "Ranks", permission: "JOB_RankView"},
              {icon: 'mdi-car-back', link: '/job/vehicles', label: "Vehicles", permission: "JOB_VehicleView"},
              {icon: 'mdi-tshirt-crew', link: '/job/uniforms', label: "Uniforms", permission: "JOB_UniformView"},
            ]
          },
        ],
        right: [
          {icon: 'mdi-cog', link: '/management', header: "Company Management", 
            menus: [
              {icon: 'mdi-email-outline', link: '/management/communications', label: "Communications", permission: "MGMT_Communications"},
              {icon: 'mdi-message-bulleted', link: '/management/chronicle', label: "Chronicle", permission: "MGMT_Chronicle"},
              {icon: 'mdi-form-select', link: '/management/forms', label: "Forms", permission: "MGMT_Forms"},
            ]
          },
          {icon: 'mdi-crown', link: '/executives', header: "Executives", 
            menus: [
              {icon: 'mdi-check-decagram-outline', link: '/executives/approvals', label: "Approvals", permission: "EXEC_Approvals"},
            ]
          },
        ]
      }
    };
  },

  methods: {
  }
};
</script>

<style scoped>
.menu-list-icon:hover {
  background-color: rgb(0, 128, 200)
}
</style>
