<template>
  <div>
    <login-fullscreen id="auth-container" v-if="AuthState.loaded && !AuthState.account.username"></login-fullscreen>
    <div v-else>
      <ImageViewDialog />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { AuthState } from '../../plugins/auth';
import LoginFullscreen from './LoginFullscreen.vue';
import ImageViewDialog from '../ImageViewDialog.vue';

export default {
  components: { LoginFullscreen, ImageViewDialog },
  computed: {
    AuthState() {
      return AuthState;
    }
  },

  methods: {
    handleImageMax(url) {
      console.log('handleImageMax', url);
      this.currentImage = url;
    }
  },

  data() {
    return {
      currentImage: null
    };
  }
};
</script>

<style lang="scss">
.fdg-editor-image {
  max-width: 100%;
}
.fdg-editor-codeblock {
  display: block;
  width: 100%;
  & code {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
  }
}

.fdg-editor-orderedList li p {
  margin-bottom: 5px;
}

.fdg-editor-orderedList li:last-child p {
  margin-bottom: 16px;
}
</style>
