// import io from "socket.io-client";
import axios from "axios";
import Vue from "vue";

// Default to development URL
let APIURL = process.env.VUE_APP_API_URL;
let socketUrl = process.env.VUE_APP_SOCKET_URL;
document.title = "Fat Duck Gaming App";

// Make the API URL accessible
Vue.prototype.$apiURL_v2 = APIURL;

// Axios configuration
axios.defaults.baseURL = APIURL;

// Socket IO
// io(SocketURL, {
//   path: SocketPath,
//   autoConnect: false,
//   auth: {},
// });

export const SocketURL = socketUrl;
export const APIUrl = APIURL;
