import '@common/plugins/api';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment';

Vue.config.productionTip = false;
document.title = 'Fat Duck Gaming - Businesses';

Vue.prototype.moment = moment;

Vue.prototype.$debugLog = function (msg) {
  if (Vue.config.devtools) {
    console.log(msg);
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
