<template>
  <div class="d-flex align-center">
    <!-- Sign in button -->
    <!-- Displayed only when not signed in -->
    <v-btn class="mx-2 ml-auto" dark :color="colorTheme" right v-if="!AuthState.account" @click="loginCard = true"> Sign In </v-btn>

    <!-- Notificactions Icon -->
    <!-- <v-icon class="mr-8 d-none d-sm-block" :color="colorTheme" v-if="signedIn">mdi-bell</v-icon> -->

    <!-- Account Display and Drop Down -->
    <v-menu offset-y class v-else style="z-index: 200">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-row align-center mr-0" v-bind="attrs" v-on="on" fluid>
          <p v-if="!mini" class="subtitle-2 ml-2 mb-0 d-none d-sm-block">
            {{ AuthState.account.username || 'Guest' }}
          </p>
          <v-avatar class="ml-2" :color="AuthState.account.displayPictureUrl ? '' : colorTheme" size="36">
            <v-img v-if="AuthState.account.displayPictureUrl" :src="AuthState.account.displayPictureUrl"></v-img>
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
        </div>
      </template>
      <v-list>
        <v-list-item dense v-for="(item, index) in items" :key="index" @click="onAccountMenuClick(item.action)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-switch v-model="$vuetify.theme.dark" @change="changeMode" label="Dark Mode" :color="colorTheme" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { logout } from '../../plugins/auth';
import { AuthState } from '../../plugins/auth';

export default {
  name: 'HeaderAccount',

  data: function () {
    return {
      loginCard: false,
      items: [{ title: 'Log Out', icon: 'mdi-logout', action: 'signout' }]
    };
  },

  computed: {
    AuthState() {
      return AuthState;
    },
    signedIn() {
      return false; //this.userData.username != null;
    }
  },

  props: {
    colorTheme: String,
    mini: Boolean,
    darkModeToggle: Boolean
  },

  methods: {
    onAccountMenuClick(action) {
      switch (action) {
        case 'signout':
          logout();
          break;
        case 'goHome':
          this.goHome();
          break;
      }
    },
    changeMode(mode) {
      localStorage.setItem('fdg_dark_mode', mode ? 'true' : 'false');
    },
    goHome() {
      this.$router.push('/home');
    }
  }
};
</script>

<style></style>
