<template>
  <v-app>
    <v-main class="bg">
      <LoginCard class="loginCardStyles" v-bind:returnURL="$route.query.returnURL" />
    </v-main>

    <v-footer class="d-none d-lg-block">
      <v-container>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col>
            &#169; Fat Duck Gaming
            {{ new Date(Date.now()).getFullYear() }} (v2.0)
          </v-col>
          <v-col style="text-align: center">WIP</v-col>
          <v-col style="text-align: right">A Kn0pee and Snakey Project</v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import LoginCard from './LoginCard';

export default {
  name: 'Login',
  components: {
    LoginCard
  }
};
</script>

<style scoped lang="scss">
.mainFlex {
  width: 100%;
  height: 100%;
}

.loginCardStyles {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  position: fixed;
}

.bg {
  background: linear-gradient(180deg, #0b67bed5 0%, #010101 100%), url(https://i.imgur.com/BXsTu9G.gif);
}

.bg-blue {
  background-color: RGB(33, 150, 243);
}

.bg-blue {
  background-color: RGB(33, 150, 243);
}
</style>
