import axios from 'axios';
import Vue from 'vue';
// import { socket } from "../plugins/api";

const _vm = new Vue({
  data() {
    return {
      loaded: false,
      account: {},
      character: {}
    };
  }
});

export const AuthState = _vm.$data;

// Initial Page Load
// Vue.prototype.$username = '';
const token = window.localStorage.getItem('quack_token');
if (token) {
  // console.log("Loaded token", token)
  axios.defaults.headers.common['Authorization'] = token;

  axios
    .get('userdata')
    .then((response) => {
      _vm.$data.account = response.data;
      _vm.$data.character = response.data.selectedCharacter;
    })
    .finally(() => {
      _vm.$data.loaded = true;
    });
} else {
  _vm.$data.loaded = true;
}

export const login = (accountid, password, otp, redirect) => {
  return new Promise((resolve, reject) => {
    let checkedOtp = null;
    if (otp != null && otp.length === 6) checkedOtp = otp;
    axios
      .post(
        'login',
        {
          accountid: accountid,
          password: password,
          twoFactorCode: checkedOtp
        },
        {
          timeout: 5000,
          timeoutErrorMessage: 'Server took too long to respond. Try again shortly.'
        }
      )
      .then((res) => {
        if (res.status == 200 && (res.data.status == null || res.data.status !== '2fa')) {
          handleLogin(res.data.userData, res.data.token, redirect);
          return resolve(res);
        } else if (res.status == 200 && res.data.status === '2fa') {
          return resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const tokenLogin = (loginToken, redirect) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        'login',
        {
          loginToken
        },
        {
          timeout: 5000,
          timeoutErrorMessage: 'Server took too long to respond. Try again shortly.'
        }
      )
      .then((res) => {
        if (res.status == 200 && (res.data.status == null || res.data.status !== '2fa')) {
          handleLogin(res.data.userData, res.data.token, redirect);
          return resolve(res);
        } else if (res.status == 200 && res.data.status === '2fa') {
          return resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const handleLogin = (user, token, redirect) => {
  // Save the user data to the Vue.store
  window.localStorage.setItem('quack_token', token);
  _vm.$data.account = user;
  _vm.$data.character = user.selectedCharacter;
  _vm.$data.loaded = true;

  // Axios configuration
  axios.defaults.headers.common['Authorization'] = token;
  if (redirect) {
    Vue.prototype.$globalRouter.push(redirect);
  }

  // Emit the login event
  window.dispatchEvent(new CustomEvent('quack_login', { token: token }));
};

export const logout = () => {
  window.localStorage.removeItem('quack_token');
  axios.defaults.headers.common['Authorization'] = undefined;
  _vm.$data.account = {};
  _vm.$data.character = {};
};

export const discord2faRequest = async (accountid, password) => {
  await axios.post(
    'login',
    {
      accountid: accountid,
      password: password,
      discord2fa: true
    },
    {
      timeout: 5000,
      timeoutErrorMessage: 'Server took too long to respond. Try again shortly.'
    }
  );
  return true;
};

export const sendDiscordReset = async (accountid) => {
  await axios.post(
    'resetpassword',
    { accountid },
    {
      timeout: 5000,
      timeoutErrorMessage: 'Server took too long to respond. Try again shortly.'
    }
  );
  return true;
};

export const resetPassword = async (token, password) => {
  await axios.post(
    'resetpassword',
    { token, password },
    {
      timeout: 5000,
      timeoutErrorMessage: 'Server took too long to respond. Try again shortly.'
    }
  );
  return true;
};

axios.interceptors.response.use((res) => {
  if (res.headers.access_token) {
    window.localStorage.setItem('quack_token', res.headers.access_token);
    axios.defaults.headers.common['Authorization'] = res.headers.access_token;
    res.headers.access_token = undefined;
  }
  return res;
});

import router from '@/router';

router.beforeEach((to, from, next) => {
  if (to.query.slt) {
    tokenLogin(to.query.slt);
    to.query.slt = undefined;
  }
  next();
});

window.addEventListener('message', (event) => {
  if (event.data && event.data.token) {
    tokenLogin(event.data.token, event.data.redirect);
  }
});
