<template>
  <v-card max-width="400" max-height="100vh" style="overflow: auto">
    <div v-if="passwordReset">
      <v-card-title>
        <v-img max-width="300" max-height="140" src="https://app.fatduckgaming.com/assets/logos/fdg.png" class="mx-auto" />
      </v-card-title>
      <v-card-text>
        <v-form ref="login_form">
          <v-alert dense text color="purple" icon="mdi-twitch">
            <strong>Streaming?</strong> This app may display sensitive information about your Fat Duck Gaming Account.
          </v-alert>
          <v-text-field label="Account ID" outlined required type="number" :value="$route.query.pwdreset_account || 'Error'" readonly />
          <v-text-field
            label="Password"
            outlined
            required
            type="password"
            @keyup.enter="requestAuth"
            :disabled="loading"
            :rules="passwordRules"
            v-model="password"
          />
          <v-text-field
            label="Confirm Password"
            outlined
            required
            type="password"
            @keyup.enter="requestAuth"
            :disabled="loading"
            v-model="confirmpassword"
            :rules="passwordRules"
          />
        </v-form>
        <v-alert border="left" type="error" text dense align="left" transition="scale-transition" v-if="alertText">
          {{ alertText }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" x-large class="mb-4" @click="resetPassword" :loading="loading"> RESET PASSWORD </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn block color="primary" x-large class="mb-4" @click="returnToLogin" :loading="loading"> Back to Login </v-btn>
      </v-card-actions>
    </div>
    <div v-else-if="state === 1">
      <v-card-title>
        <v-img max-width="300" max-height="140" src="https://app.fatduckgaming.com/assets/logos/fdg.png" class="mx-auto" />
      </v-card-title>
      <v-card-text>
        <v-form ref="login_form">
          <v-alert dense text color="purple" icon="mdi-twitch">
            <strong>Streaming?</strong> This app may display sensitive information about your Fat Duck Gaming Account.
          </v-alert>
          <p v-if="returnURL != null" class="text-center">Sign in to continue to '{{ returnURL }}'</p>
          <v-text-field label="Account ID" outlined required type="number" :disabled="loading" v-model="accountid" :rules="accountidRules" />
          <v-text-field
            label="Password"
            outlined
            required
            type="password"
            @keyup.enter="requestAuth"
            :disabled="loading"
            v-model="password"
            :rules="passwordRules"
          />
        </v-form>
        <v-alert border="left" type="error" text dense align="left" transition="scale-transition" v-if="alertText">
          {{ alertText }}
        </v-alert>
        <v-container class="pa-0">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col align="end">
              <v-dialog v-model="dialogState" width="500" absolute>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on">Forgot?</v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline"> Trouble Logging In? </v-card-title>
                  <v-card-text>
                    <strong>Create an account</strong> by connecting to our
                    <a href="https://connect.fatduckgaming.com/index.html" class="text-decoration-none"> FiveM Server </a>
                    . An account will be automatically created for you upon first login. <br />
                    <br />
                    <strong> To set or update your password </strong> enter your account ID and click the "Send discord reset" button below. You will receive a
                    link which will work for 5 minutes and will allow you to set your password.

                    <br />
                    <br />Additionally, you can connect to our
                    <a href="https://connect.fatduckgaming.com/index.html" class="text-decoration-none"> FiveM Server </a>
                    , open chat using the <kbd>T</kbd> key, then type <kbd>/updatepassword</kbd>. This will open a dialog with instructions.
                    <br />
                    <br />
                    If you require further assistance, please contact our community representatives through
                    <a href="https://discord.gg/fatduckgaming" class="text-decoration-none"> Discord </a>
                    , or on our
                    <a href="https://www.fatduckgaming.com" class="text-decoration-none"> Forums </a>
                    .
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="d-flex flex-row align-center">
                    <v-text-field
                      label="Account ID"
                      outlined
                      required
                      type="number"
                      :disabled="loading"
                      v-model="accountid"
                      :rules="accountidRules"
                      hide-details
                    ></v-text-field>
                    <v-spacer />
                    <v-btn text @click="sendDiscordReset">Send Discord Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" x-large class="mb-4" @click="requestAuth" :loading="loading"> SIGN IN </v-btn>
      </v-card-actions>
    </div>
    <div v-else-if="state === 2">
      <v-card-title>
        <v-img max-width="200" width="200" max-height="200" height="200" src="https://app.fatduckgaming.com/assets/logos/FDG_Lock.png" class="mx-auto" />
      </v-card-title>
      <v-card-text class="text-center">
        <p class="text-h5">Two-Factor Required</p>
        <div class="text-body-2">Enter the 6-digit authentication code from your Authenticator App.</div>
        <v-otp-input class="my-5" v-model="otp" type="number" @finish="requestAuth"></v-otp-input>
        <v-btn block large color="primary" :disabled="otp.length != 6" @click.stop="requestAuth" :loading="loading">
          {{ otp.length != 6 ? `${6 - otp.length} digits remaining` : 'VALIDATE' }}
        </v-btn>
        <v-alert type="error" class="text-left mt-5" text v-if="alertText">
          {{ alertText }}
        </v-alert>
      </v-card-text>
      <v-card-text class="text-center">
        <v-btn block large color="primary" @click.stop="discord2faRequest" :loading="loading"> Use Discord 2FA </v-btn>
        <v-alert dense text color="blue" icon="mdi-security" v-if="discord2faRequested" class="mt-4">
          Please click the link sent to your discord to complete your sign in. The link will expire in 30 seconds.
        </v-alert>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { login, discord2faRequest, sendDiscordReset, resetPassword } from '../../plugins/auth';

export default {
  name: 'LoginCard',

  props: {
    returnURL: String
  },

  computed: {
    passwordReset() {
      if (this.$route.query.pwdreset) {
        return this.$route.query.pwdreset;
      } else {
        return false;
      }
    }
  },

  data: () => ({
    loading: false,
    alertText: null,
    dialogState: false,
    accountid: null,
    password: null,
    confirmpassword: null,
    discord2faRequested: false,
    state: 1,
    otp: '',

    accountidRules: [(v) => !!v || 'Required', (v) => !isNaN(Number(v)) || 'Must be a number', (v) => Number(v) > 0 || 'Account IDs are greater than 0'],
    passwordRules: [(v) => !!v || 'Required']
  }),

  methods: {
    async sendDiscordReset() {
      this.dialogState = false;
      await sendDiscordReset(this.accountid);
      this.alertText = null;
    },

    async returnToLogin() {
      this.$router.push('/');
    },

    async resetPassword() {
      this.alertText = null;
      this.loading = true;

      // Ensures only valid data entered in fields
      if (this.confirmpassword != this.password || !this.$refs.login_form.validate()) {
        this.loading = false;
        this.alertText = "Passwords invalid or didn't match";
        return;
      }

      try {
        await resetPassword(this.passwordReset, this.password);
      } catch (error) {
        if (error.code == 401) {
          this.alertText = 'Your reset code has expired, please send another password reset';
        }
      } finally {
        this.loading = false;
        this.$router.push('/');
      }
    },

    async requestAuth() {
      this.alertText = null;
      this.loading = true;
      // Ensures only valid data entered in fields
      if (this.state === 1 && !this.$refs.login_form.validate()) {
        this.loading = false;
        this.alertText = 'Missing or invalid data in required input(s)';
        return;
      }
      try {
        const resp = await login(this.accountid, this.password, this.otp);
        if (resp.data?.status === '2fa') {
          this.opt = '';
          this.state = 2;
          this.loading = false;
        } else if (resp.data?.status === 'discord2fa') {
          this.discord2fa = true;
        } else {
          this.returnURL != null ? this.$router.push(this.returnURL) : this.$router.push('/');
        }
      } catch (error) {
        this.alertText = error.response?.data ?? error;
        this.loading = false;
      }
    },

    async discord2faRequest() {
      this.alertText = null;
      this.loading = true;

      if (await discord2faRequest(this.accountid, this.password)) {
        this.discord2faRequested = true;
        this.loading = false;
      }
    }
  }
};
</script>
