<template>
  <quack-auth-app>
    <v-app>
      <Header v-if="this.$store.state.characterSelected && this.$route.fullPath != '/login'"/>

      <router-view />

      <v-snackbar v-for="snack in snackbars" :key="snack.id" :value="true" :color="snack.color" :timeout="-1" bottom app text>
        {{ snack.text }}
      </v-snackbar>
    </v-app>
  </quack-auth-app>
</template>

<script>
import Header from './components/Header.vue';
import QuackAuthApp from '../common/components/auth/QuackAuthApp.vue';
import { mapState, mapActions } from 'vuex';

// Imports`
export default {
  name: 'Businesses',
  components: {
    QuackAuthApp,
    Header,
  },

  data: function () {
    return {
      navDrawer: false,
    };
  },
  computed: {
    ...mapState(['snackbars']),
  },
  methods: {
    ...mapActions(['addSnack']),
  },
  watch:{
    $route(to) {
      if (to.path != "/login" && !this.$store.state.characterSelected) {
        this.$router.push("login")
        this.addSnack({ text: 'You need to login to do that', color: 'red' })
      }

      if (to.path == "/login") {
        this.$store.state.selectedCharacter = []
        this.$store.state.characterSelected = false
        this.$router.push("login")
      }
    }
} 
};
</script>

<style lang="scss">
  ::-webkit-scrollbar {
    display: none;
  }
</style>
