import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/BusinessCharSelect.vue'),
  },
  {
    path: '/finance',
    component: () => import('../modules/finance/FinanceHome.vue'),
    children: [
      {
        path: 'summary',
        component: () => import('../modules/finance/views/Summary.vue'),
      },
      {
        path: 'expenses',
        component: () => import('../modules/finance/views/Expenses.vue'),
      },
      {
        path: 'income',
        component: () => import('../modules/finance/views/Income.vue'),
      },
    ]
  },
  {
    path: '/hr',
    component: () => import('../modules/hr/HRHome.vue'),
    children: [
      {
        path: 'home',
        component: () => import('../modules/hr/HRHome.vue'),
      },
      {
        path: 'employees',
        component: () => import('../modules/hr/Employee/Employees.vue'),
      },
      {
        path: 'applications',
        component: () => import('../modules/hr/Applications/Applications.vue'),
      },
      {
        path: 'administration',
        component: () => import('../modules/hr/Administration/Administration.vue'),
      },
      {
        path: 'directory',
        component: () => import('../modules/hr/Directory/EmployeeDirectory.vue'),
      },
    ]
  },
  {
    path: '/job',
    component: () => import('../modules/ranks/RankHome.vue'),
    children: [
      {
        path: 'ranks',
        component: () => import('../modules/ranks/Ranks/RankManage.vue'),
      },
      {
        path: 'ranks/:rankId',
        component: () => import('../modules/ranks/Ranks/RankManage.vue'),
      },
      {
        path: 'uniforms',
        component: () => import('../modules/ranks/Uniforms/UniformManage.vue'),
      },
      {
        path: 'vehicles',
        component: () => import('../modules/ranks/Vehicles/VehicleManage.vue'),
      },
      {
        path: '*',
        redirect: '/123'
      }
    ]
  },
  {
    path: '/management',
    component: () => import('../modules/management/ManagementHome.vue'),
    children: [
      {
        path: 'communications',
        component: () => import('../modules/management/Communications.vue'),
      },
      {
        path: 'chronicle',
        component: () => import('../modules/management/Chronicle.vue'),
      },
      {
        path: 'forms',
        component: () => import('../modules/management/Forms.vue'),
      },
      {
        path: 'forms/:formId',
        component: () => import('../modules/management/Forms.vue'),
        children: [
          {
            name: 'formManage',
            path: 'manage',
            component: () => import('../modules/management/Forms/FormManage.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/forms',
    component: () => import('../modules/management/ManagementHome.vue'),
    children: [
      {
        path: ':formid/viewform',
        component: () => import('../../common/components/forms/FormContainer.vue'),
      },
      {
        path: ':formid/confirmation/:confirmationid',
        component: () => import('../../common/components/forms/FormConfirmation.vue'),
      },
      {
        path: 'failed',
        component: () => import('../../common/components/forms/FormFailed.vue'),
      },
      {
        path: 'response/:responseId',
        component: () => import('../../common/components/forms/ViewResponse.vue'),
        children: [
          {
            name: 'view',
            path: 'view',
            component: () => import('../../common/components/forms/ViewResponse.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/executives',
    component: () => import('../modules/executives/ExecutiveHome.vue'),
    children: [
      {
        path: 'approvals',
        component: () => import('../modules/executives/Approvals.vue'),
      }
    ]
  },
  {
    path: '*',
    redirect: '/login'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
