import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    employees: [
      {name: "Luke Keaton1", grade: "4", gradeLabel: "Mod Mech", phone: "09", accountid: 9, charid: 3},
      {name: "Luke Keaton2", grade: "4", gradeLabel: "Mod Mech", phone: "09", accountid: 9, charid: 2},
      {name: "Luke Keaton3", grade: "4", gradeLabel: "Mod Mech", phone: "09", accountid: 9, charid: 1},
      {name: "Luke Keaton4", grade: "4", gradeLabel: "Mod Mech", phone: "09", accountid: 9, charid: 4},
    ],

    selectedCharacter: [],
    characterSelected: false,

    snackbars: [],
    intervals: [],
    snackCounter: 1
  },

  mutations: {
    ADD_SNACK(state, { text, color, id }) {
      state.snackbars.push({
        text: text,
        color: color,
        id: id
      });
    },
    INCREMENT_SNACK_COUNTER(state) {
      state.snackCounter++;
    },
    POP_SNACK(state, id) {
      const index = state.snackbars.findIndex((el) => el.id === id);
      if (index === -1) return;
      state.snackbars.splice(index, 1);
    }
  },

  actions: {
    addSnack(context, { text, color = 'blue' }) {
      if (text == null || color == null) {
        return;
      }
      context.commit('ADD_SNACK', {
        text: text,
        color: color,
        id: context.state.snackCounter
      });
      setTimeout(
        (id) => {
          context.commit('POP_SNACK', id);
        },
        6000,
        context.state.snackCounter
      );
      context.commit('INCREMENT_SNACK_COUNTER');
    }
  }
});

export default store;
