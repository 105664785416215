<template>
  <v-dialog :value="src != null" @click:outside="src = null" width="unset">
    <img :src="src" style="max-height: 80vh; max-width: 80vw" />
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      src: null
    };
  },

  mounted() {
    this.$root.$on('maxImage', (src) => {
      this.src = src;
    });
  }
};
</script>
